<template>
  <div v-if="isStaging" class="pointer-events-none fixed text-sm">
    <span
      class="block origin-bottom-right rounded-br-md bg-red-900 pb-1 pl-1 pr-2 text-white"
      >Staging</span
    >
  </div>

  <header class="py-4">
    <h1>
      <router-link
        :to="{ name: 'home' }"
        class="flex flex-col items-center text-lg leading-5 text-sky-600"
      >
        <span>recreatieschap</span>
        <span class="font-bold">marrekrite</span>
      </router-link>
    </h1>
  </header>

  <main
    class="mx-auto mb-6 max-w-lg space-y-4"
    :class="{ '!max-w-3xl': size === '3xl' }"
  >
    <div v-if="['new-orer'].includes(route.name?.toString() || '')">
      <p class="bg-white p-4 text-center shadow-lg">
        We zijn tijdelijk gesloten voor bestellingen.
      </p>
    </div>

    <router-view
      v-else
      :user="user"
      @set:size="size = $event"
      @logout="logout"
      @reload-user="loadUser"
    />

    <div class="flex flex-col items-center">
      <locale-picker />
    </div>

    <div
      class="pointer-events-none bottom-2 right-2 text-center text-xs text-neutral-300 sm:fixed sm:-z-10"
    >
      <span class="inline-block w-24 truncate">{{ appVersion }}</span>
    </div>
  </main>
</template>

<script setup lang="ts">
  import { inject, ref, watch } from 'vue'
  import { RouterView, useRoute } from 'vue-router'
  import LocalePicker from './components/LocalePicker.vue'
  import * as Sentry from '@sentry/vue'

  const isStaging = import.meta.env.VITE_ENVIRONMENT === 'staging'

  const size = ref<null | '3xl'>(null)
  const route = useRoute()
  watch(route, () => (size.value = null))

  const user = ref<null | Person>(null)

  const loadUser = async () => {
    const resp = await fetch('/auth/status')
    const data = await resp.json()

    if (data.success) {
      user.value = data.result

      Sentry.setUser({
        id: data.result.uuid,
        name: `${data.result.firstName} ${data.result.lastName}`,
      })
    }
  }

  loadUser()

  const appVersion = inject<string>('appVersion')

  const logout = async () => {
    const resp = await fetch('/auth', { method: 'DELETE' })
    if (resp.ok) {
      user.value = null
    }
  }
</script>

<style lang="postcss">
  body {
    @apply bg-neutral-100;
  }
</style>
